<template>
  <report-section title="趋势图">
    <template v-slot:content>
      <div>
        <div
          :id="title"
          :style="`width: 900px; height: ${440 * zoom}px ;zoom:${
            1 / zoom
          };transform:scale(${zoom});transform-origin:0 0`"
        ></div>
      </div>
    </template>
  </report-section>
</template>
          
    <script>
import ReportSection from "@c/report-detail/single-blood-oxygen-report/common/report-section.vue";
import { DateTool } from "@js/date-tool.js";
export default {
  components: {
    ReportSection,
  },

  props: {
    reportDetailModel: Object,
  },

  data() {
    return {
      title: "section-trend-chart",
      zoom: 1,
      chart: undefined,
    };
  },

  mounted() {
    //解决tooltip偏移问题（结合chart的style）
    const app = document.getElementById("app");
    this.zoom = window.getComputedStyle(app, null).zoom;
    this.chart = this.$echarts.init(document.getElementById(this.title));
    this.drawLine();
  },

  watch: {
    reportDetailModel() {
      this.drawLine();
    },
  },

  methods: {
    drawLine() {
      this.chart.setOption({
        axisPointer: {
          link: [
            {
              xAxisIndex: [0, 1, 2],
            },
          ],
        },
        grid: [
          {
            right: 20,
            left: 60,
            top: 40,
            bottom: 240,
          },
          {
            right: 20,
            left: 60,
            top: 250,
            bottom: 60,
          }
        ],
        xAxis: [
          {
            type: "time",
            axisLine: {
              lineStyle: {
                color: "#BABABA",
              },
            },
            splitLine: {
              show: true,
            },
            gridIndex: 0,
          },
          {
            type: "time",
            axisLine: {
              lineStyle: {
                color: "#BABABA",
              },
            },
            splitLine: {
              show: true,
            },
            gridIndex: 1,
          }
        ],
        yAxis: [
          {
            name: "血氧饱和度趋势图(%)",
            type: "value",
            min: 65,
            max: 100,
            axisLine: {
              lineStyle: {
                color: "#BABABA",
              },
            },
            gridIndex: 0,
          },
          {
            name: "脉率趋势图",
            type: "value",
            min: 45,
            max: this.reportDetailModel.reportDetail.maxPr,
            axisLine: {
              lineStyle: {
                color: "#BABABA",
              },
            },
            gridIndex: 1,
          }
        ],
        series: [
          {
            name: "血氧饱和度",
            type: "line",
            symbol: "none",
            data: this.reportDetailModel.bloodOxygenTrandArray,
            lineStyle: {
              width: 1,
            },
            color: "#DF7555",
            xAxisIndex: 0,
            yAxisIndex: 0,
          },
          {
            name: "脉率",
            type: "line",
            symbol: "none",
            data: this.reportDetailModel.pulseTrandArray,
            lineStyle: {
              width: 1,
            },
            color: "#68B680",
            xAxisIndex: 1,
            yAxisIndex: 1,
          }
        ],
        tooltip: {
          show: true,
          trigger: "axis",
          formatter: (params) => {
            return this.formatterToolTip(params);
          },
        },
      });
    },

    formattedAxisLabel(value) {
      switch (value) {
        case 0:
          return "";
        case 1:
          return "D";
        case 2:
          return "L";
        case 3:
          return "R";
        case 4:
          return "W";
        default:
          break;
      }
    },

    formatterToolTip(params) {
      var tip = "";
      params.forEach((item, index) => {
        if (index == 0) {
          const timeStr = DateTool.millisecondTimestampToStr(
            item.data[0],
            "Y_M_D h:m:s"
          );
          tip += timeStr + "<br />";
        }
        const marker = item.marker;
        const seriesName = item.seriesName;
        let value = "";
        if (seriesName == "睡眠分期") {
          switch (item.value[1]) {
            case 1:
              value = "深睡期";
              break;
            case 2:
              value = "浅睡期";
              break;
            case 3:
              value = "眼动期";
              break;
            case 4:
              value = "清醒期";
              break;
            default:
              break;
          }
        } else {
          value = item.value[1];
        }
        let unit = "";
        switch (seriesName) {
          case "血氧饱和度":
            unit = "%";
            break;
          case "脉率":
            unit = "bpm";
            break;
          case "睡眠分期":
            unit = "";
            break;
          default:
            break;
        }
        tip += marker + seriesName + ": " + value + unit + "<br />";
      });
      return tip;
    },
  },
};
</script>
          
<style lang="scss" scoped>


.line-chart {
  @include z-index-2;
  width: 900px !important;
  height: 750px;
  box-sizing: border-box;
}
</style>