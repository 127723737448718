<template>
  <div v-if="reportDetailModel" class="single-blood-oxygen-report" ref="imageDom">
    <img class="single-blood-oxygen-report-logo" :src="reportDetailModel.sopReportLogo"
      v-if="reportDetailModel.sopReportLogo != null && reportDetailModel.sopReportLogo != ''">
    <p class="single-blood-oxygen-report-title">{{ reportDetailModel.sopReportHeaders || "" }}</p>
    <p class="single-blood-oxygen-report-subtitle">{{ reportDetailModel.sopReportSubTitle || "" }}</p>
    <div class="single-blood-oxygen-report-button-container" :class="useClassWithDifferentBrower()">
      <!-- <button class="single-blood-oxygen-report-button-container-item noPrint" @click="onShare">分享</button> -->
      <button class="single-blood-oxygen-report-button-container-item noPrint" @click="onDownloadPNG">下载PNG</button>
      <button class="single-blood-oxygen-report-button-container-item noPrint" @click="onDownloadPDF">下载PDF</button>
      <button class="single-blood-oxygen-report-button-container-item noPrint" @click="onPrint">打印</button>
    </div>
    <section-time :reportDetailModel="reportDetailModel"></section-time>
    <section-user-info :reportDetailModel="reportDetailModel" @onEditUserInfoSucceed="onEditUserInfoSucceed">
    </section-user-info>
    <section-blood-oxygen :reportDetailModel="reportDetailModel"></section-blood-oxygen>
    <section-oxygen-minus-table :reportDetailModel="reportDetailModel"></section-oxygen-minus-table>
    <section-pulse :reportDetailModel="reportDetailModel"></section-pulse>
    <section-hrv v-if="isShowHRV" :reportDetailModel="reportDetailModel"></section-hrv>
    <section-period :reportDetailModel="reportDetailModel"></section-period>
    <div v-if="isShowLiteTable">
      <section-trend-chart-template1 :reportDetailModel="reportDetailModel"></section-trend-chart-template1>
      <div style="zoom: 0.85;">
        <div class="single-blood-oxygen-report-section">
          <section-oxygen-minus-event-table :reportDetailModel="reportDetailModel"></section-oxygen-minus-event-table>
          <section-oxygen-minus-time-table :reportDetailModel="reportDetailModel"></section-oxygen-minus-time-table>
        </div>
        <div class="single-blood-oxygen-report-section">
          <section-blood-oxygen-table :reportDetailModel="reportDetailModel"></section-blood-oxygen-table>
          <section-period-table :reportDetailModel="reportDetailModel"></section-period-table>
        </div>
      </div>
    </div>
    <div v-else>
      <section-trend-chart :reportDetailModel="reportDetailModel"></section-trend-chart>
      <div class="single-blood-oxygen-report-section">
        <section-blood-oxygen-table :reportDetailModel="reportDetailModel"></section-blood-oxygen-table>
        <section-blood-oxygen-chart :reportDetailModel="reportDetailModel"></section-blood-oxygen-chart>
      </div>
      <div class="single-blood-oxygen-report-section">
        <section-oxygen-minus-event-table :reportDetailModel="reportDetailModel"></section-oxygen-minus-event-table>
        <section-oxygen-minus-event-chart :reportDetailModel="reportDetailModel"></section-oxygen-minus-event-chart>
      </div>
      <div style="page-break-after: always"></div>
      <div class="single-blood-oxygen-report-section">
        <section-oxygen-minus-time-table :reportDetailModel="reportDetailModel"></section-oxygen-minus-time-table>
        <section-oxygen-minus-time-chart :reportDetailModel="reportDetailModel"></section-oxygen-minus-time-chart>
      </div>
      <div class="single-blood-oxygen-report-section">
        <section-period-table :reportDetailModel="reportDetailModel"></section-period-table>
        <section-period-chart :reportDetailModel="reportDetailModel"></section-period-chart>
      </div>
    </div>
    <section-suggestion :reportDetailModel="reportDetailModel" @onEditSuggestionSucceed="onEditSuggestionSucceed">
    </section-suggestion>
  </div>
</template>

<script>
import KeyValueUnit from "@c/report-detail/single-blood-oxygen-report/common/key-value-unit/key-value-unit-blood-oxygen-report.vue";
import SectionTime from "@c/report-detail/single-blood-oxygen-report/section-time.vue";
import SectionUserInfo from "@c/report-detail/single-blood-oxygen-report/section-user-info.vue";
import SectionBloodOxygen from "@c/report-detail/single-blood-oxygen-report/section-blood-oxygen.vue";
import SectionOxygenMinusTable from "@c/report-detail/single-blood-oxygen-report/section-oxygen-minus-table.vue";
import SectionPulse from "@c/report-detail/single-blood-oxygen-report/section-pulse.vue";
import SectionHrv from "@c/report-detail/single-blood-oxygen-report/section-hrv.vue";
import SectionPeriod from "@c/report-detail/single-blood-oxygen-report/section-period.vue";
import SectionTrendChart from "@c/report-detail/single-blood-oxygen-report/section-trend-chart.vue";
import SectionTrendChartTemplate1 from "@c/report-detail/single-blood-oxygen-report/section-trend-chart-template1.vue";
import SectionBloodOxygenTable from "@c/report-detail/single-blood-oxygen-report/section-blood-oxygen-table.vue";
import SectionBloodOxygenChart from "@c/report-detail/single-blood-oxygen-report/section-blood-oxygen-chart.vue";
import SectionOxygenMinusEventTable from "@c/report-detail/single-blood-oxygen-report/section-oxygen-minus-event-table.vue";
import SectionOxygenMinusEventChart from "@c/report-detail/single-blood-oxygen-report/section-oxygen-minus-event-chart.vue";
import SectionOxygenMinusTimeTable from "@c/report-detail/single-blood-oxygen-report/section-oxygen-minus-time-table.vue";
import SectionOxygenMinusTimeChart from "@c/report-detail/single-blood-oxygen-report/section-oxygen-minus-time-chart.vue";
import SectionPeriodTable from "@c/report-detail/single-blood-oxygen-report/section-period-table.vue";
import SectionPeriodChart from "@c/report-detail/single-blood-oxygen-report/section-period-chart.vue";
import SectionSuggestion from "@c/report-detail/single-blood-oxygen-report/section-suggestion.vue";
import { ReportDetailModel } from "@c/report-detail/single-blood-oxygen-report/report-detail-model.js";
import { ReportPreviewTool } from "@js/report-preview-tool.js";
import html2canvas from "html2canvas";
import { DateTool } from "@js/date-tool.js";
import deviceTool from "@js/deviceTool.js";
import appHandler from "@js/appHandler.js"

export default {
  components: {
    KeyValueUnit,
    SectionTime,
    SectionUserInfo,
    SectionBloodOxygen,
    SectionOxygenMinusTable,
    SectionHrv,
    SectionPulse,
    SectionPeriod,
    SectionTrendChart,
    SectionTrendChartTemplate1,
    SectionBloodOxygenTable,
    SectionBloodOxygenChart,
    SectionOxygenMinusEventTable,
    SectionOxygenMinusEventChart,
    SectionOxygenMinusTimeTable,
    SectionOxygenMinusTimeChart,
    SectionPeriodTable,
    SectionPeriodChart,
    SectionSuggestion,
  },

  data() {
    return {
      enterInfo: undefined,
      reportDetailModel: undefined,
      uid: undefined
    };
  },

  computed: {
    isShowHRV() {
      return this.reportDetailModel.xyReportTemplateType == SPO2_REPORT_TEMPLATE.中国曲棍球国家代表队
    },

    isShowLiteTable() {
      return this.reportDetailModel.xyReportTemplateType == SPO2_REPORT_TEMPLATE.广东医科大学附属第二医院
    }
  },

  async created() {
    this.enterInfo = this.$router.history.current.query;
    this.reportDetailModel = await this.getBloodOxygenReportDetail();
  },

  methods: {
    onDownloadPNG() {
      let createMillisecondTimestamp = DateTool.strToMillisecondTimestamp(this.reportDetailModel.startTimeStr)
      let createTimeStr = DateTool.millisecondTimestampToStr(createMillisecondTimestamp, "M月D日 h：m")
      html2canvas(this.$refs.imageDom, {
        useCORS: true, //是否尝试使用CORS从服务器加载图像
      }).then((canvas) => {
        var imgData = canvas.toDataURL("image/jpeg");
        //如果是Android，发送消息
        if (deviceTool.getDeviceType() === "Android") {
          appHandler.sendMsg({
            path: this.$router.history.current.path,
            origin: "DownloadPNG",
            data: {
              name: this.reportDetailModel.nameStr,
              createTime: createTimeStr,
              imgData: imgData
            }
          })
        } else {
          ReportPreviewTool.downloadImageData(
            this.reportDetailModel.nameStr,
            createTimeStr,
            imgData
          );
        }
      });
    },

    async onDownloadPDF() {
      try {
        let downloadURL = await this.getBloodOxygenReportDownloadURL()
        //如果是Android，发送消息
        if (deviceTool.getDeviceType() === "Android") {
          this.downloadPDFWithAndroid(downloadURL, "DownloadPDF")
        } else {
          this.downloadPDFWithNormal(downloadURL)
        }
      } catch (error) {
        this.$toast.showRed(error)
      }
    },

    async onPrint() {
      if (deviceTool.getDeviceType() === "Android") {
        try {
          let downloadURL = await this.getBloodOxygenReportDownloadURL()
          //如果是Android，发送消息
          this.downloadPDFWithAndroid(downloadURL, "Print")
        } catch (error) {
          this.$toast.showRed(error)
        }
      } else {
        window.print();
      }
    },
    /**
     * 分享
     */
    async onShare() {
      try {
        let downloadURL = await this.getBloodOxygenReportDownloadURL()
        await this.$copyText(downloadURL)
        this.$toast.showGreen(MSG.copyToClipboardSuccess);
      } catch (error) {
        this.$toast.showRed(error)
      }
    },

    async onEditSuggestionSucceed() {
      this.$toast.showGreen("编辑成功");
      this.reportDetailModel = await this.getBloodOxygenReportDetail();
    },

    async onEditUserInfoSucceed() {
      this.$toast.showGreen("编辑成功");
      this.reportDetailModel = await this.getBloodOxygenReportDetail();
    },

    async getBloodOxygenReportDetail() {
      const params = {
        reportId: this.enterInfo.reportId,
      };
      try {
        const data = await this.$api.getBloodOxygenReportDetail(params)
        const reportDetailModel = new ReportDetailModel(data)
        return reportDetailModel;
      } catch (error) {
        this.$toast.showRed(error)
      }
    },

    async getBloodOxygenReportDownloadURL() {
      const params = {
        reportId: this.enterInfo.reportId,
      };
      try {
        const downloadURL = await this.$api.getBloodOxygenReportDownloadURL(params)
        return downloadURL;
      } catch (error) {
        throw error
      }
    },
    useClassWithDifferentBrower() {
      //为了控制打印三页睡眠初筛报告，测试了五个浏览器FireFox，Chrome，Edge，Opera，Safari，只有Safari异常，所以添加这个方法调整css。
      const browserType = deviceTool.getBrowserType()
      if (browserType === 'Safari') {
        return 'single-blood-oxygen-report-button-container-Safari-no-print'
      } else {
        return ''
      }
    },
    downloadPDFWithAndroid(url, oringin) {
      let createMillisecondTimestamp = DateTool.strToMillisecondTimestamp(this.reportDetailModel.startTimeStr)
      let createTimeStr = DateTool.millisecondTimestampToStr(createMillisecondTimestamp, "M月D日 h：m")
      appHandler.sendMsg({
        path: this.$router.history.current.path,
        origin: oringin,
        data: {
          name: this.reportDetailModel.nameStr,
          createTime: createTimeStr,
          url: url
        }
      })
    },
    downloadPDFWithNormal(url) {
      ReportPreviewTool.downloadBloodOxygenReport(url);
    }

  },
};
</script>

<style lang="scss" scoped>
.single-blood-oxygen-report {
  width: 980px;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 50px 40px 100px;

  &-logo {
    height: 66px;
    width: 66px;
    position: absolute;
  }

  &-title {
    color: black;
    font-size: 24px;
    font-weight: 600;

    height: 24px;
    display: flex;
    justify-content: center;
  }

  &-subtitle {
    color: black;
    font-size: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    margin-top: 5px;

  }

  &-button-container {

    display: flex;
    justify-content: flex-end;
    margin-top: 44px;
    margin-bottom: 30px;

    &-item {
      color: white;
      background-color: #325FAC;
      border-radius: 4px;
      width: 88px;
      height: 32px;
      margin-left: 20px;
    }
  }

  &-section-title {
    color: black;
    font-size: 24px;
    font-weight: 600;
    line-height: 73px;
    width: 100%;
  }

  &-section {
    display: flex;
  }
}

@media print {

  //打印时的按钮功能区
  .single-blood-oxygen-report-button-container-Safari-no-print {
    height: 40px;
    margin: 0px;
  }

  .noPrint {
    background-color: white !important;
  }
}
</style>