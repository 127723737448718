<template>
  <report-section title="氧减事件统计">
    <template v-slot:content>
      <div class="section-blood-oxygen-table">
        <table class="table">
          <thead>
            <tr class="table-row">
              <td class="table-row-item table-row-item-bold">血氧范围</td>
              <td class="table-row-item table-row-item-bold">次数</td>
              <td class="table-row-item table-row-item-bold">总占比</td>
            </tr>
            <tr class="table-row">
              <td
                class="table-row-item table-row-item-bold"
                v-text="`95%≤SpO2${signal}100%`"
              ></td>
              <td class="table-row-item">
                {{ reportDetailModel.oxygenMinusEventLess100TimesStr }}
              </td>
              <td class="table-row-item">
                {{ reportDetailModel.oxygenMinusEventLess100RatioStr }}
              </td>
            </tr>
            <tr class="table-row">
              <td
                class="table-row-item table-row-item-bold"
                v-text="`90%≤SpO2${signal}95%`"
              ></td>
              <td class="table-row-item">
                {{ reportDetailModel.oxygenMinusEventLess95TimesStr }}
              </td>
              <td class="table-row-item">
                {{ reportDetailModel.oxygenMinusEventLess95RatioStr }}
              </td>
            </tr>
            <tr class="table-row">
              <td
                class="table-row-item table-row-item-bold"
                v-text="`85%≤SpO2${signal}90%`"
              ></td>
              <td class="table-row-item">
                {{ reportDetailModel.oxygenMinusEventLess90TimesStr }}
              </td>
              <td class="table-row-item">
                {{ reportDetailModel.oxygenMinusEventLess90RatioStr }}
              </td>
            </tr>
            <tr class="table-row">
              <td
                class="table-row-item table-row-item-bold"
                v-text="`80%≤SpO2${signal}85%`"
              ></td>
              <td class="table-row-item">
                {{ reportDetailModel.oxygenMinusEventLess85TimesStr }}
              </td>
              <td class="table-row-item">
                {{ reportDetailModel.oxygenMinusEventLess85RatioStr }}
              </td>
            </tr>
            <tr class="table-row">
              <td
                class="table-row-item table-row-item-bold"
                v-text="`75%≤SpO2${signal}80%`"
              ></td>
              <td class="table-row-item">
                {{ reportDetailModel.oxygenMinusEventLess80TimesStr }}
              </td>
              <td class="table-row-item">
                {{ reportDetailModel.oxygenMinusEventLess80RatioStr }}
              </td>
            </tr>
            <tr class="table-row">
              <td
                class="table-row-item table-row-item-bold"
                v-text="`70%≤SpO2${signal}75%`"
              ></td>
              <td class="table-row-item">
                {{ reportDetailModel.oxygenMinusEventLess75TimesStr }}
              </td>
              <td class="table-row-item">
                {{ reportDetailModel.oxygenMinusEventLess75RatioStr }}
              </td>
            </tr>
            <tr class="table-row">
              <td
                class="table-row-item table-row-item-bold"
                v-text="`65%≤SpO2${signal}70%`"
              ></td>
              <td class="table-row-item">
                {{ reportDetailModel.oxygenMinusEventLess70TimesStr }}
              </td>
              <td class="table-row-item">
                {{ reportDetailModel.oxygenMinusEventLess70RatioStr }}
              </td>
            </tr>
            <tr class="table-row">
              <td
                class="table-row-item table-row-item-bold"
                v-text="`60%≤SpO2${signal}65%`"
              ></td>
              <td class="table-row-item">
                {{ reportDetailModel.oxygenMinusEventLess65TimesStr }}
              </td>
              <td class="table-row-item">
                {{ reportDetailModel.oxygenMinusEventLess65RatioStr }}
              </td>
            </tr>
            <tr class="table-row">
              <td
                class="table-row-item table-row-item-bold"
                v-text="`SpO2${signal}60%`"
              ></td>
              <td class="table-row-item">
                {{ reportDetailModel.oxygenMinusEventLess60TimesStr }}
              </td>
              <td class="table-row-item">
                {{ reportDetailModel.oxygenMinusEventLess60RatioStr }}
              </td>
            </tr>
          </thead>
        </table>
      </div>
    </template>
  </report-section>
</template>
      
      <script>
import ReportSection from "@c/report-detail/single-blood-oxygen-report/common/report-section.vue";
import KeyValueUnit from "@c/report-detail/single-blood-oxygen-report/common/key-value-unit/key-value-unit-blood-oxygen-report.vue";

export default {
  components: {
    ReportSection,
    KeyValueUnit,
  },

  props: {
    reportDetailModel: Object,
  },

  data() {
    return {
      signal: "<",
    };
  },
};
</script>
      
      <style lang="scss" scoped>

.table {
  border: 1px solid #707070;

  &-row {
    display: flex;
    justify-content: space-evenly;

    :nth-child(1){
        width: 230px;
    }

    :nth-child(2){
        width: 91px;
    }

    :nth-child(3){
        width: 129px;
    }

    &-item {
      color: black;
      font-size: 16px;
      font-weight: 400;
      border: 1px solid #707070;
      height: 42px;
      display: flex;
      align-items: center;
      justify-content: center;

      &-bold {
        font-weight: 600;
      }
    }
  }
}
</style>