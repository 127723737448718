<template>
    <report-section title="HRV">
      <template v-slot:content>
        <div class="section-pulse-content">
          <key-value-unit
            title="SDNN"
            :value="reportDetailModel.sdnn"
          ></key-value-unit>
        </div>
      </template>
    </report-section>
  </template>
    
    <script>
  import ReportSection from "@c/report-detail/single-blood-oxygen-report/common/report-section.vue";
  import KeyValueUnit from "@c/report-detail/single-blood-oxygen-report/common/key-value-unit/key-value-unit-blood-oxygen-report.vue";
  
  export default {
    components: {
      ReportSection,
      KeyValueUnit,
    },
  
    props: {
      reportDetailModel: Object,
    },
  };
  </script>
    
    <style lang="scss" scoped>
  
  .section-pulse-content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  </style>