<template>
  <report-section title="睡眠分期统计">
    <template v-slot:content>
      <div class="section-period-content">
        <key-value-unit
          title="总睡眠时长（TST）"
          :value="reportDetailModel.totalHoursStr"
        ></key-value-unit>
        <key-value-unit
          title="浅睡期时长"
          :value="reportDetailModel.lightHoursStr"
        ></key-value-unit>
        <key-value-unit
          title="深睡期时长"
          :value="reportDetailModel.deepHoursStr"
        ></key-value-unit>
        <key-value-unit
          title="REM期时长"
          :value="reportDetailModel.remHoursStr"
        ></key-value-unit>
      </div>
    </template>
  </report-section>
</template>
    
    <script>
import ReportSection from "@c/report-detail/single-blood-oxygen-report/common/report-section.vue";
import KeyValueUnit from "@c/report-detail/single-blood-oxygen-report/common/key-value-unit/key-value-unit-blood-oxygen-report.vue";

export default {
  components: {
    ReportSection,
    KeyValueUnit,
  },

  props: {
    reportDetailModel: Object,
  },
};
</script>
    
    <style lang="scss" scoped>

.section-period-content {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>