<!-- main->pap->提示窗中使用 -->
<template>
  <div class="key-unit">
    <p class="key-unit-key">{{ title }}：</p>
    <slot name="content"></slot>
  </div>
</template>
  
  <script>
export default {
  props: {
    title: String,
  },
};
</script>
  
<style lang="scss" scoped>

.key-unit {
  font-size: 16px;
  height: 40px;
  display: flex;
  align-items: center;

  &-key {
    color: black;
    font-weight: 600;
    min-width: 60px;
  }
}
</style>