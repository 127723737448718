//通过value获取key
function findKey(obj, value, compare = (a, b) => a === b) {
    return Object.keys(obj).find(k => compare(obj[k], value)); //找不到返回undefined
};

//获取key数组
function getObjectKeys(object) {
    var keys = [];
    for (var property in object) keys.push(property);
    return keys;
};

//获取value数组
function getObjectValues(object) {
    var values = [];
    for (var property in object) values.push(object[property]);
    return values;
};

//获取key-value数组
function getKeyValueArray(object) {
    var array = [];
    const keys = getObjectKeys(object)
    const values = getObjectValues(object)
    keys.forEach((item, index) => {
        const itemObject = {
            label: item,
            value: values[index]
        }
        array.push(itemObject)
    })
    return array
}



export {
    findKey,
    getObjectKeys,
    getObjectValues,
    getKeyValueArray
}