<template>
  <button class="edit-button no-print" :class="[
    inputIsEditable
      ? 'edit-button-is-editable'
      : 'edit-button-is-not-editable',
  ]" @click="onClick">
    {{ inputIsEditable ? "保存" : "编辑" }}
  </button>
</template>

<script>
export default {
  props: {
    inputIsEditable: Boolean,
  },

  methods: {
    onClick() {
      if (this.inputIsEditable) {
        this.$emit("onSave");
      } else {
        this.$emit("onEdit");
      }
    },
  },
};
</script>

<style lang="scss" scoped>


@media print {
  .no-print {
    background-color: white !important;;
  }
}

.edit-button {
  color: white;
  border-radius: 4px;
  width: 88px;
  height: 32px;

  &-is-editable {
    background-color: #6cc291;
  }

  &-is-not-editable {
    background-color: #325FAC;
  }
}
</style>