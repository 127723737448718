<template>
  <report-section title="睡眠分期统计">
    <template v-slot:content>
      <div>
        <table class="table">
          <thead>
            <tr class="table-row">
              <td class="table-row-item table-row-item-bold">睡眠阶段</td>
              <td class="table-row-item table-row-item-bold">持续时间</td>
              <td class="table-row-item table-row-item-bold">总测试时间占比</td>
              <td class="table-row-item table-row-item-bold">总睡眠时间占比</td>
            </tr>
            <tr class="table-row">
              <td class="table-row-item table-row-item-bold">清醒期</td>
              <td class="table-row-item">
                {{ reportDetailModel.wakeMinutesStr }}
              </td>
              <td class="table-row-item">
                {{ reportDetailModel.wakeMinutesTestPercentStr }}
              </td>
              <td class="table-row-item">
                {{ reportDetailModel.wakeMinutesSleepPercentStr }}
              </td>
            </tr>
            <tr class="table-row">
              <td class="table-row-item table-row-item-bold">眼动期</td>
              <td class="table-row-item">
                {{ reportDetailModel.remMinutesStr }}
              </td>
              <td class="table-row-item">
                {{ reportDetailModel.remMinutesTestPercentStr }}
              </td>
              <td class="table-row-item">
                {{ reportDetailModel.remMinutesSleepPercentStr }}
              </td>
            </tr>
            <tr class="table-row">
              <td class="table-row-item table-row-item-bold">浅睡期</td>
              <td class="table-row-item">
                {{ reportDetailModel.lightMinutesStr }}
              </td>
              <td class="table-row-item">
                {{ reportDetailModel.lightMinutesTestPercentStr }}
              </td>
              <td class="table-row-item">
                {{ reportDetailModel.lightMinutesSleepPercentStr }}
              </td>
            </tr>
            <tr class="table-row">
              <td class="table-row-item table-row-item-bold">深睡期</td>
              <td class="table-row-item">
                {{ reportDetailModel.deepMinutesStr }}
              </td>
              <td class="table-row-item">
                {{ reportDetailModel.deepMinutesTestPercentStr }}
              </td>
              <td class="table-row-item">
                {{ reportDetailModel.deepMinutesSleepPercentStr }}
              </td>
            </tr>
          </thead>
        </table>
      </div>
    </template>
  </report-section>
</template>
          
          <script>
import ReportSection from "@c/report-detail/single-blood-oxygen-report/common/report-section.vue";
import KeyValueUnit from "@c/report-detail/single-blood-oxygen-report/common/key-value-unit/key-value-unit-blood-oxygen-report.vue";

export default {
  components: {
    ReportSection,
    KeyValueUnit,
  },

  props: {
    reportDetailModel: Object,
  },
};
</script>
          
          <style lang="scss" scoped>

.table {
  border: 1px solid #707070;

  &-row {
    display: flex;
    justify-content: space-evenly;

    :nth-child(1) {
      width: 102px;
    }

    :nth-child(2) {
      width: 102px;
    }

    :nth-child(3) {
      width: 127px;
    }

    :nth-child(4) {
      width: 119px;
    }

    &-item {
      color: black;
      font-size: 16px;
      font-weight: 400;
      border: 1px solid #707070;
      height: 42px;
      display: flex;
      align-items: center;
      justify-content: center;

      &-bold {
        font-weight: 600;
      }
    }
  }
}
</style>