const deviceTool = {}
deviceTool.getBrowserType = () => {
  const userAgent = navigator.userAgent; // 获取浏览器 User-Agent 字符串

  const isOpera = userAgent.indexOf('Opera') > -1 || userAgent.indexOf('OPR') > -1; // 判断是否为 Opera 浏览器
  const isFirefox = userAgent.indexOf('Firefox') > -1; // 判断是否为 Firefox 浏览器
  const isSafari = userAgent.indexOf('Safari') > -1 && userAgent.indexOf('Chrome') === -1; // 判断是否为 Safari 浏览器
  const isChrome = userAgent.indexOf('Chrome') > -1 && userAgent.indexOf('Safari') > -1; // 判断是否为 Chrome 浏览器
  const isEdge = userAgent.indexOf('Edge') > -1; // 判断是否为 Edge 浏览器
  const isIE = userAgent.indexOf('compatible') > -1 && userAgent.indexOf('MSIE') > -1; // 判断是否为 IE 浏览器

  // 返回浏览器类型
  if (isOpera) {
    return 'Opera';
  } else if (isFirefox) {
    return 'Firefox';
  } else if (isSafari) {
    return 'Safari';
  } else if (isChrome) {
    return 'Chrome';
  } else if (isEdge) {
    return 'Edge';
  } else if (isIE) {
    return 'IE';
  } else {
    return 'Unknown';
  }
}

deviceTool.getDeviceType = () => {
  const userAgent = navigator.userAgent || window.opera;

  // 检查是否为移动设备
  if (/android/i.test(userAgent)) {
    return "Android";
  }
  if (/iPad|iPhone|iPod/i.test(userAgent)) {
    return "iOS";
  }
  return deviceTool.getBrowserType()
}

export default deviceTool