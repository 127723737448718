<template>
  <report-section title="氧减事件时间分布占比">
    <template v-slot:content>
      <div>
        <div class="bar-chart" :id="title"></div>
      </div>
    </template>
  </report-section>
</template>
          
    <script>
import ReportSection from "@c/report-detail/single-blood-oxygen-report/common/report-section.vue";
import KeyValueUnit from "@c/report-detail/single-blood-oxygen-report/common/key-value-unit/key-value-unit-blood-oxygen-report.vue";

export default {
  components: {
    ReportSection,
    KeyValueUnit,
  },

  props: {
    reportDetailModel: Object,
  },

  data() {
    return {
      title: "oxygenMinusTimeChart",
    };
  },
  mounted() {
    this.chart = this.$echarts.init(document.getElementById(this.title));
    this.drawLine();
  },

  watch: {
    reportDetailModel() {
      this.drawLine();
    },
  },

  methods: {
    drawLine() {
      this.chart.setOption({
        dataset: {
          source: [
            ["product", "时间占比"],
            ["<10", this.reportDetailModel.oxygenMinusTimeLess10Ratio],
            ["<20", this.reportDetailModel.oxygenMinusTimeLess20Ratio],
            ["<30", this.reportDetailModel.oxygenMinusTimeLess30Ratio],
            ["<40", this.reportDetailModel.oxygenMinusTimeLess40Ratio],
            ["<50", this.reportDetailModel.oxygenMinusTimeLess50Ratio],
            ["<60", this.reportDetailModel.oxygenMinusTimeLess60Ratio],
            ["≥60", this.reportDetailModel.oxygenMinusTimeMore60Ratio],
          ],
        },
        grid: {
          right: 0,
        },
        legend: {
          orient: "horizontal",
          right: 0,
        },
        xAxis: {
          type: "category",
          axisLine: {
            lineStyle: {
              color: "#BABABA",
            },
          },
        },
        yAxis: {
          max: 100,
          axisLine: {
            lineStyle: {
              color: "#BABABA",
            },
          },
          splitLine: {
            lineStyle: {
              type: "dashed",
            },
          },
        },
        series: [
          {
            type: "bar",
            label: {
              show: true,
              position: "top",
              formatter: (value) => {
                return `${value.data[1].toFixed(1)}%`;
              },
              color: "#4474BE",
            },
          },
        ],
      });
    },
  },
};
</script>
          
    <style lang="scss" scoped>


.bar-chart {
  @include z-index-2;
  width: 450px !important;
  height: 395px;
  box-sizing: border-box;
}
</style>